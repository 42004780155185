import React from 'react';
import './home.css';

const Home: React.FC = () => {
	return (
		<>
			<section>
				<article>
					<h1>What Is A Genrephile?</h1>
					<p>
						The term "genre films" crops up with some frequency these days when reading about the production and critique of movies, particularly horror. The term was
						originally used as "genre fiction" to describe particular styles of writing that had popular appeal but were not considered "high art," such as Penny
						Dreadfuls. Genre fiction applies, now, to movies as well as books, and has found a place in popular culture when discussing horror movies in all of their
						incarnations. Horror will often include elements of other genres, such as science fiction or comedy. Given this context, a genrephile is someone who loves
						horror in all of its forms.
					</p>
				</article>
			</section>
		</>
	);
};

export default Home;
