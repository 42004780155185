import React from 'react';
import './xr.css';

const XR: React.FC = () => {
    return (
        <>
            AUGMENTED/VIRTUAL REALITY
        </>
    )};

export default XR;