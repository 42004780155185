import { Route, Routes } from 'react-router-dom';
import Articles from '../../components/articles/articles';
import Home from '../../components/home/home';
import Treatments from '../../components/treatments/treatments';
import XR from '../../components/xr/xr';

const RoutesConfig = () => {
    return (
		<Routes>
			<Route path='/home' element={<Home />} />
			<Route path='/articles' element={<Articles />} />
			<Route path='/treatments' element={<Treatments />} />
			<Route path='/xr' element={<XR />} />
			<Route path='/' element={<Home />} />
		</Routes>
	);
}

export default RoutesConfig;