import React from 'react';
import RoutesConfig from '../common/routesConfig/routesConfig';
import './App.css';

function App() {
	return (
		<>
			<div className='appContainer'>
				<RoutesConfig />
			</div>
			{/* <Nav /> */}
		</>
	);
}

export default App;
